'use client';
import { configureStore, combineReducers } from '@reduxjs/toolkit';

import HeaderState from '../Slices/HeaderSlice/headerState';
import NewsViewSlice from '../Slices/NewsViewSlice/NewsView';
import HeadingSlice from '../Slices/HeadingSlice/HeadingSlice';
import NavMobileSlice from '@/Redux/Slices/NavMobileSlice/NavMobileSlice';
import SourseSlice from '@/Redux/Slices/SourseSlice/SourseSlice';
import PollsState from '@/Redux/Slices/PollsSlice/PollsState';
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistReducer,
	createMigrate
} from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import GeoState from '../Slices/GeoSlice/GeoState';
import FilterState from '@/Redux/Slices/FilterSlice/FilterState';

// saga
import createSagaMiddleware from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import getPostArticle, {
	GET_POSTS,
	getPostsSaga
} from '../saga/getPostArticle';
import getPostArticlePublisher, {
	GET_POSTPUB,
	getPostsPublisherSaga
} from '../saga/getPostsPublisher';

import getPostsTagsSaga, {
	GET_POSTTAGS,
	getPostTagsSaga
} from '../saga/getPostsTags';

import getPostProperty, {
	GET_PROPERTY,
	getPostsPropertySaga
} from '../saga/getPostProperty';

import getPostTopic, { GET_TOPIC, getTopicSaga } from '../saga/getPostTopic';

import getPostsFinam, {
	GET_FINAMS,
	getPostsFinamSaga
} from '../saga/getPostFinam';

import getPostNewsLocal, {
	GET_LOCALNEWS,
	getPostsLocalNewsSaga
} from '../saga/getPostLocalNews';

import getPostComment, {
	GET_COMMENTS,
	getPostsCommentSaga
} from '../saga/getPostComments';

import getPollsVote, {
	GET_VOTE,
	getPollsVoteAllsaga
} from '../saga/getPollsVote';

import getPollsAll, { GET_POLLS, getPollsAllsaga } from '../saga/getPolls';

import getPostSimiliarArticle, {
	GET_SIMILARARTICLES,
	getPostsSimiliarArticlesSaga
} from '../saga/getPostSimiliarArticles';

import getPostArticleNew, {
	GET_POSTSNEWS,
	getPostArticleNewSaga
} from '../saga/getPostArticleNews';

import getPostLocation, {
	GET_LOCATION,
	getPostLocationSaga
} from '../saga/getPostLocation';
import getPostSearchSlice, {
	GET_SEARCH,
	getPostSearchSaga
} from '../saga/getPostSearch';
import getPostsFullArticle, {
	GET_POSTFULLARTICLE,
	getFullArticleSaga
} from '../saga/getPostFullArticle';
import getPostSimular, {
	GET_SIMULAR,
	getPostSimularSaga
} from '../saga/getPostSimular';

import getPostStoryArticle, {
	GET_POSTS_STORY,
	getPostsSagaStory
} from '../saga/getPostStoryArticle';

import persistStore from 'redux-persist/es/persistStore';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';
import { initialState as initHeader } from '../Slices/HeaderSlice/states';
import { initialState as initHadings } from '../Slices/HeadingSlice/states';
import { initialState as initGeoState } from '../Slices/GeoSlice/states';
import { initialState as initSourses } from '../Slices/SourseSlice/states';
import { initialState as initPolls } from '../Slices/PollsSlice/states';
import { initialState as initNewsView } from '../Slices/NewsViewSlice/states';

// проверки на SSR
const createNoopStorage = () => {
	return {
		getItem(_key: any) {
			return Promise.resolve(null);
		},
		setItem(_key: any, value: any) {
			return Promise.resolve(value);
		},
		removeItem(_key: any) {
			return Promise.resolve();
		}
	};
};
const storage =
	typeof window !== 'undefined'
		? createWebStorage('local')
		: createNoopStorage();

// инишал стейт из слайсов
const initialStates = {
	// удалять отсюда что не надо вырезать
	// initHeader,
	initHadings,
	initGeoState,
	initSourses,
	initPolls,
	initNewsView

	// Добавьте начальные состояния для всех остальных слайсов
};
// мигации
const migrations: any = {
	0: (state: any) => {
		return {
			...state
		};
	}
};

const persistConfig = {
	key: 'root-0.2.2',
	storage,
	// менять версию тут
	version: 0,
	migrate: createMigrate(migrations, { debug: true }),
	whitelist: [
		'HeaderState',
		'HeadingSlice',
		'GeoState',
		'SourseSlice',
		'PollsState',
		'NewsViewSlice'
	]
};

// saga init
const sagaMiddleware = createSagaMiddleware();

// основной запуск всех saga
function* sagas() {
	yield takeEvery(GET_POSTS_STORY, getPostsSagaStory);
	yield takeEvery(GET_POSTS, getPostsSaga);
	yield takeEvery(GET_POSTPUB, getPostsPublisherSaga);
	yield takeEvery(GET_POSTTAGS, getPostTagsSaga);
	yield takeEvery(GET_TOPIC, getTopicSaga);
	yield takeEvery(GET_FINAMS, getPostsFinamSaga);
	yield takeEvery(GET_LOCALNEWS, getPostsLocalNewsSaga);
	yield takeEvery(GET_PROPERTY, getPostsPropertySaga);
	yield takeEvery(GET_COMMENTS, getPostsCommentSaga);
	yield takeEvery(GET_SIMILARARTICLES, getPostsSimiliarArticlesSaga);
	yield takeEvery(GET_POLLS, getPollsAllsaga);
	yield takeEvery(GET_VOTE, getPollsVoteAllsaga);
	yield takeEvery(GET_POSTSNEWS, getPostArticleNewSaga);
	yield takeEvery(GET_LOCATION, getPostLocationSaga);
	yield takeEvery(GET_SEARCH, getPostSearchSaga);
	yield takeEvery(GET_POSTFULLARTICLE, getFullArticleSaga);
	yield takeEvery(GET_SIMULAR, getPostSimularSaga);
}

const rootReducer = combineReducers({
	getPostStoryArticle,
	FilterState,
	getPostLocation,
	PollsState,
	getPollsAll,
	getPostSimiliarArticle,
	getPollsVote,
	getPostProperty,
	getPostsFinam,
	getPostTopic,
	getPostArticlePublisher,
	getPostArticle,
	getPostsTagsSaga,
	getPostNewsLocal,
	getPostComment,
	HeaderState,
	NewsViewSlice,
	HeadingSlice,
	NavMobileSlice,
	GeoState,
	SourseSlice,
	getPostArticleNew,
	getPostSearchSlice,
	getPostsFullArticle,
	getPostSimular
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: any = configureStore({
	// reducer: rootReducer,
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			// thunk: false,
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
			}
		}).concat(sagaMiddleware)
});
sagaMiddleware.run(sagas);
export const persister = persistStore(store, null, () => {
});

export type rootState = ReturnType<typeof persistedReducer>;

export type AppDispatch = ReturnType<typeof store.dispatch>;
